import React, { Component } from 'react';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Fab,
  Link,
  TextField,
  Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

export default class ForgotPassword extends Component {
  state = {
    step: 0,
    email: '',
    errorEmail: true,
    emailError: '',
    newPassword: '',
    errorPass: true,
    passError: '',
    confirmationCode: '',
    validEmail: false,
    finalNumbers: '',
    isForgotLoading: false
  }
  
  handleNewPass = () => {
    const { email } = this.state
    this.setState({ isForgotLoading: true });

    Auth.forgotPassword(email)
    .then(data => {
      this.setState({
        step: 1,
        finalNumbers: data['CodeDeliveryDetails']['Destination'],
        isForgotLoading: false
      })
    })
    .catch(err => {
      this.setState({
        isForgotLoading: false,
        errorEmail: false,
        emailError: 'O e-mail inserido não está cadastrado'
      });
      console.log('Send Code '+JSON.stringify(err))
    })
  }

  handleConfirmationCode = () => {
    const { email, confirmationCode, newPassword } = this.state
    this.setState({ isForgotLoading: true });

    Auth.forgotPasswordSubmit(email, confirmationCode, newPassword)
    .then(data => {
      this.setState({ step: 3 })
    })
    .catch(err => {
      this.setState({
        isForgotLoading: false,
        errorPass: false,
        passError: 'A senha deve conter no mínimo 8 dígitos e ter ao menos 1 letra maiúscula, minúscula e número'
      })
      console.log('Set New Pass '+JSON.stringify(err))
    })
  }

  handleResendCode = () => {
    const { email } = this.state

    Auth.forgotPassword(email)
    .then(data => this.setState({ step: 1 }))
    .catch(err => console.log('Resend Code '+JSON.stringify(err)))
  }

  handleEmail = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ errorEmail: false, emailError: 'Campo obrigatório' });
    }
    this.setState({ email: query, errorEmail: true, emailError: '' });
  }

  validateEmail = (event) => {
    const query = event.target.value;

    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(query) === true) {
      this.setState({ errorEmail: true });
      return false
    } else if (!query) {
      this.setState({ errorEmail: true });
    } else {
      this.setState({ errorEmail: false, emailError: 'Endereço de e-mail inválido' });
    }
  }

  handlePass = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ errorPass: false, passError: 'Campo obrigatório' });
    }
    this.setState({ newPassword: query, errorPass: true, passError: '' });
  }

  handleCode = (event) => {
    const query = event.target.value;
    this.setState({ confirmationCode: query });
  }

  render() {
    const { step, errorEmail, emailError, errorPass, passError, isForgotLoading } = this.state

    return (
      <MuiThemeProvider theme={theme}>
        <Container maxWidth='xs'>
          {step === 0
          ? <Grid container direction='row' justify='center' alignItems='center' style={{ padding: 16 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={!errorEmail}
                label='E-mail'
                variant='outlined'
                value={this.state.email}
                onChange={this.handleEmail}
                onBlur={this.validateEmail}
                helperText={emailError}
                style={{ width: '100%', marginBottom: 16 }}
                type='email' />
            </Grid>
            <Grid item xs={12}>
              <Fab style={{ width: '100%' }} variant='extended' color='primary' onClick={this.handleNewPass}>
                CONTINUAR
                {isForgotLoading &&
                  <FontAwesomeIcon icon={faCircleNotch} size='2x' color='#fff' spin style={{ position: 'absolute', right: 12 }} />
                }
              </Fab>
            </Grid>
          </Grid>
          : step === 1
          ? <Grid container direction='row' justify='center' alignItems='center' style={{ padding: 16 }}>
            <Grid item xs={12}>
              <Typography component='p'>Insira o Código de Verificação enviado para o celular de final {this.state.finalNumbers.substr(this.state.finalNumbers.length - 4)}</Typography>
              <TextField
                fullWidth
                label='Código de Verificação'
                variant='outlined'
                value={this.state.confirmationCode}
                onChange={this.handleCode}
                style={{ width: '100%', marginTop: 16, marginBottom: 16 }}
                type='text' />
            </Grid>
            <Grid item xs={12}>
              <Fab style={{ width: '100%', marginBottom: 16 }} variant='extended' color='primary' onClick={() => { this.setState({ step: 2 }) }}>
                ALTERAR SENHA
                {isForgotLoading &&
                  <FontAwesomeIcon icon={faCircleNotch} size='2x' color='#fff' spin style={{ position: 'absolute', right: 12 }} />
                }
              </Fab>
            </Grid>
            <Grid item xs={12}>
              <Typography component='p' style={{ textAlign: 'center' }}><Link style={{ color: '#9a2376', cursor: 'pointer' }} variant='extended' onClick={ this.handleResendCode }>Não recebeu o código? Reenviar.</Link></Typography>
            </Grid>
          </Grid>
          : step === 2
          ? <Grid container direction='row' justify='center' alignItems='center' style={{ padding: 16 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={!errorPass}
                label='Nova senha'
                variant='outlined'
                value={this.state.newPassword}
                onChange={this.handlePass}
                helperText={passError}
                style={{ width: '100%', marginBottom: 16 }}
                type='password' />
            </Grid>
            <Grid item xs={12}>
              <Fab style={{ width: '100%' }} variant='extended' color='primary' onClick={ this.handleConfirmationCode }>
                CONFIRMAR
                {isForgotLoading &&
                  <FontAwesomeIcon icon={faCircleNotch} size='2x' color='#fff' spin style={{ position: 'absolute', right: 12 }} />
                }
              </Fab>
            </Grid>
          </Grid>
          : <Grid container direction='column' justify='center' alignItems='center' style={{ padding: 16 }}>
              <Grid item xs={12}>
                <FontAwesomeIcon icon={faCheckCircle} size='3x' color='#0c0' style={{ textAlign: 'center', marginBottom: 12 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography component='p' style={{textAlign: 'center'}}>Senha alterada com sucesso, clique no botão abaixo para fechar.</Typography>
              </Grid>
            </Grid>
          }
        </Container>
      </MuiThemeProvider>
    );
  }
}