import React, { Component } from 'react';
import {
  API,
  graphqlOperation } from 'aws-amplify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  Grid,
  CircularProgress } from '@material-ui/core';
import { setHistoric } from '../actions';
import * as queries from '../graphql/queries';
import * as d3 from 'd3';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

const GRAPH_MARGIN = 20
const GRAPH_BAR_WIDTH = 5
const colors = {
  axis: '#ddd',
  bars: '#9a2376'
};

class Historic extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      firstTextInput: null,
      lastTextInput: null,
    };
  }

  state = {
    isLoaded: false,
    checkFilter: this.props.user.subItem,
    data: this.props.historic,
  }

  componentDidMount() {
    this.getClientHistoric()
    .then(() => {
      this.setState({ isLoaded: true });
      console.log('historic is stored');
    })
    .catch(err => console.log('error on historic: '+JSON.stringify(err)));
  }

  async getClientHistoric() {
    const { user, dispatch } = this.props
    const clientHistory = await API.graphql(graphqlOperation(queries.listHistorics, {
      filter: {
        clientId: {
          eq: user.clientId
        }
      },
      limit: 1000,
    }))
    {Object.keys(clientHistory.data.listHistorics.items).map(num => {
      dispatch(
        setHistoric(
          clientHistory.data.listHistorics.items[num].responses,
          clientHistory.data.listHistorics.items[num].nps,
          clientHistory.data.listHistorics.items[num].q1,
          clientHistory.data.listHistorics.items[num].q2,
          clientHistory.data.listHistorics.items[num].q3,
          clientHistory.data.listHistorics.items[num].q4,
          clientHistory.data.listHistorics.items[num].q5,
          clientHistory.data.listHistorics.items[num].cq1,
          clientHistory.data.listHistorics.items[num].cq2,
          clientHistory.data.listHistorics.items[num].id
        )
      )
      return null;
    })}
  }

  render() {
    const { user, historic, filter } = this.props;
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100vh' }}>
            <Grid item>
              <CircularProgress color='primary' />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    } else {
      const kpi = Object.keys(historic).map(num => historic[num])[0];
      const width = document.getElementsByClassName('myContent')[0].clientWidth * 0.93;
      const SVGHeight = 240;
      const SVGWidth = width - 24;
      const graphHeight = SVGHeight - 2 * GRAPH_MARGIN;
      const graphWidth = SVGWidth - 2 * GRAPH_MARGIN;
      const checkIfExist = JSON.stringify(historic).includes(filter) ? true : false;

      if (!checkIfExist) {
        return(
          <MuiThemeProvider theme={theme}>
            <Grid container justify='center' alignItems='center' style={{ marginTop: 64 }}>
              <Grid item>
                <p style={{ textAlign: 'center' }} ><FontAwesomeIcon icon={faExclamationTriangle} color='#999' size='4x' /></p>
                <p style={{ textAlign: 'center', color: '#999' }} >Ainda não existe nenhuma resposta.</p>
              </Grid>
            </Grid>
          </MuiThemeProvider>
        )
      } else {
        return(
          <MuiThemeProvider theme={theme}>
            <Container className='heightPage'>
              <Grid container direction='row' justify='center' alignItems='center' spacing={4} className='historicPage'>
                <Grid item xs={12}>
                  {Object.keys(kpi)
                  .filter(i => 
                    i === 'responses'
                  )
                  .map((num, i) => {
                    if (kpi[num] !== null && JSON.stringify(kpi[num]).includes([filter])) {
                      let data = []
                      Object.entries(kpi[num][filter]).forEach(([key, value]) => {
                          let month = key.substring(4,6) === '01'
                          ? 'Jan'
                          : key.substring(4,6) === '02'
                          ? 'Fev'
                          : key.substring(4,6) === '03'
                          ? 'Mar'
                          : key.substring(4,6) === '04'
                          ? 'Abr'
                          : key.substring(4,6) === '05'
                          ? 'Mai'
                          : key.substring(4,6) === '06'
                          ? 'Jun'
                          : key.substring(4,6) === '07'
                          ? 'Jul'
                          : key.substring(4,6) === '08'
                          ? 'Ago'
                          : key.substring(4,6) === '09'
                          ? 'Set'
                          : key.substring(4,6) === '10'
                          ? 'Out'
                          : key.substring(4,6) === '11'
                          ? 'Nov'
                          : 'Dez'
                        if (parseInt(key) >= parseInt(user.createdAt) && value !== 0) {
                          data.push({
                            value: value,
                            label: month+'/'+key.substring(2, 4)
                          });
                        }
                      });

                      // X scale point
                      const xDomain = data.map(item => item.label)
                      const xRange = [0, graphWidth]
                      const x = d3.scalePoint()
                                  .domain(xDomain)
                                  .range(xRange)
                                  .padding(1)

                      // Y scale linear
                      const maxValue = d3.max(data, d => d.value)
                      const topValue = Math.ceil(maxValue)
                      const yDomain = [0, (topValue)]
                      const yRange = [0, graphHeight]
                      const y = d3.scaleLinear()
                                  .domain(yDomain)
                                  .range(yRange)

                      // top axis and middle axis
                      const middleValue = topValue / 2

                      return(
                      <Paper
                        style={{
                          marginTop: 36,
                          padding: 24
                        }}
                        key={num}>
                          <p style={{ fontSize: 16, marginTop: 0, fontWeight: 'bold', color: '#999', paddingBottom: 6 }}>{kpi[num]['label'].toUpperCase()}</p>
                          <svg width={width} height={SVGHeight+20} className='barChart'>
                            <g y={graphHeight + GRAPH_MARGIN}>
                              {/* top axis */}
                              <line
                                x1={0}
                                y1={0}
                                x2={graphWidth}
                                y2={0}
                                stroke={colors.axis}
                                strokeDasharray={[3, 3]}
                                strokeWidth={0.5}
                              />
                              {/* middle axis */}
                              <line
                                x1={0}
                                y1={y(middleValue)}
                                x2={graphWidth}
                                y2={y(middleValue)}
                                stroke={colors.axis}
                                strokeDasharray={[3, 3]}
                                strokeWidth={0.5}
                              />
                              {/* bottom axis */}
                              <line
                                x1={0}
                                y1={y(topValue)}
                                x2={graphWidth}
                                y2={y(topValue)}
                                stroke={colors.axis}
                                strokeWidth={1}
                              />

                              {/* bars */}
                              {data.map(item => (
                                <g key={'g'+item.label}>
                                  <rect
                                    key={item.label}
                                    x={x(item.label) - (GRAPH_BAR_WIDTH / 2) - 2.5}
                                    y={y(topValue) - y(item.value)}
                                    rx={4}
                                    ry={4}
                                    width={16}
                                    height={y(item.value)}
                                    fill={colors.bars}
                                  />
                                  <text
                                    key={'text'+item.label}
                                    fontSize='10'
                                    stroke='#fff'
                                    strokeWidth={3}
                                    x={x(item.label) - (GRAPH_BAR_WIDTH / 2) + 5}
                                    y={(y(topValue) - y(item.value)) - 4 < 12 ? (y(topValue) - y(item.value)) + 12 : (y(topValue) - y(item.value)) - 4}
                                    textAnchor='middle'>{item.value > 0 ? item.value : null}</text>
                                  <text
                                    key={'text'+item.label}
                                    fontSize='10'
                                    fill='#000'
                                    stroke='#000'
                                    strokeWidth={0.5}
                                    x={x(item.label) - (GRAPH_BAR_WIDTH / 2) + 5}
                                    y={(y(topValue) - y(item.value)) - 4 < 12 ? (y(topValue) - y(item.value)) + 12 : (y(topValue) - y(item.value)) - 4}
                                    textAnchor='middle'>{item.value > 0 ? item.value : null}</text>
                                </g>
                              ))}

                              {/* labels */}
                              {data.map(item => (
                                <text
                                key={item.label}
                                fill='#999'
                                fontSize='10'
                                x={x(item.label)}
                                y={y(topValue) + 22}
                                transform={`rotate(45,${x(item.label)},${y(topValue) + 22})`}
                                textAnchor='middle'>{item.label}</text>
                              ))}
                            </g>
                          </svg>
                        </Paper>
                      )
                    }
                    return null;
                  })}
                  {Object.keys(kpi)
                  .filter(i => 
                    i === 'nps'
                  )
                  .map((num, i) => {
                    if (kpi[num] !== null && JSON.stringify(kpi[num]).includes([filter])) {
                      let data = []
                      Object.entries(kpi[num][filter]).forEach(([key, value]) => {
                        if (parseInt(key) >= parseInt(user.createdAt)) {
                          let month = key.substring(4,6) === '01'
                          ? 'Jan'
                          : key.substring(4,6) === '02'
                          ? 'Fev'
                          : key.substring(4,6) === '03'
                          ? 'Mar'
                          : key.substring(4,6) === '04'
                          ? 'Abr'
                          : key.substring(4,6) === '05'
                          ? 'Mai'
                          : key.substring(4,6) === '06'
                          ? 'Jun'
                          : key.substring(4,6) === '07'
                          ? 'Jul'
                          : key.substring(4,6) === '08'
                          ? 'Ago'
                          : key.substring(4,6) === '09'
                          ? 'Set'
                          : key.substring(4,6) === '10'
                          ? 'Out'
                          : key.substring(4,6) === '11'
                          ? 'Nov'
                          : 'Dez'
                          data.push({
                            value: value,
                            label: month+'/'+key.substring(2, 4)
                          });
                        }
                      });

                      // X scale point
                      const xDomain = data.map(item => item.label)
                      const xRange = [0, graphWidth]
                      const x = d3.scalePoint()
                        .domain(xDomain)
                        .range(xRange)
                        .padding(1)

                      // Y scale linear
                      const topValue = 100
                      const yDomain = [-110, 110]
                      const yRange = [0, graphHeight]
                      const y = d3.scaleLinear()
                        .domain(yDomain)
                        .range(yRange)

                      // top axis and middle axis
                      const middleValue = 0

                      return(
                      <Paper
                        style={{
                          marginTop: 36,
                          padding: 24
                        }}
                        key={num}>
                          <p style={{ fontSize: 16, marginTop: 0, fontWeight: 'bold', color: '#999', paddingBottom: 6 }}>{kpi[num]['label'].toUpperCase()}</p>
                          <svg width={width} height={SVGHeight+20}>
                            <g y={graphHeight + GRAPH_MARGIN}>
                              {/* top axis */}
                              <line
                                x1={0}
                                y1={10}
                                x2={graphWidth}
                                y2={10}
                                stroke={colors.axis}
                                strokeDasharray={[3, 3]}
                                strokeWidth={0.5}
                              />

                              {/* middle axis */}
                              <line
                                x1={0}
                                y1={y(middleValue) + 5}
                                x2={graphWidth}
                                y2={y(middleValue) + 5}
                                stroke={colors.axis}
                                strokeDasharray={[3, 3]}
                                strokeWidth={0.5}
                              />

                              {/* bottom axis */}
                              <line
                                x1={0}
                                y1={y(topValue)}
                                x2={graphWidth}
                                y2={y(topValue)}
                                stroke={colors.axis}
                                strokeWidth={1}
                              />

                              {/* line */}
                              {data.map((item, index) => {
                                return(
                                  <g key={'g'+item.label}>
                                    <line
                                      x1={x(item.label) - (GRAPH_BAR_WIDTH / 2) - 2.5}
                                      y1={(y(topValue) - y(item.value)) + 10}
                                      x2={x(data[index < (Object.entries(data).length - 1) ? index + 1 : index].label) - (GRAPH_BAR_WIDTH / 2) - 2.5}
                                      y2={(y(topValue) - y(data[index < (Object.entries(data).length - 1) ? index + 1 : index].value)) + 10}
                                      stroke={colors.bars}
                                      strokeWidth='2'
                                    />
                                    <circle
                                      r={9}
                                      stroke={colors.bars}
                                      fill={'white'}
                                      cx={x(item.label) - (GRAPH_BAR_WIDTH / 2) - 2.5}
                                      cy={(y(topValue) - y(item.value)) + 10}
                                    />
                                    <text
                                      key={'text'+item.label}
                                      fontSize={8}
                                      x={(x(item.label) - (GRAPH_BAR_WIDTH / 2)) - 2}
                                      y={(y(topValue)- y(item.value)) + 13}
                                      textAnchor='middle'>{item.value}</text>
                                  </g>
                                )
                              })}

                              {/* labels */}
                              {data.map(item => (
                                <text
                                key={item.label}
                                fill='#999'
                                fontSize='10'
                                x={x(item.label)}
                                y={y(topValue) + 22}
                                transform={`rotate(45,${x(item.label)},${y(topValue) + 22})`}
                                textAnchor='middle'>{item.label}</text>
                              ))}
                            </g>
                          </svg>
                        </Paper>
                      )
                    }
                    return null;
                  })}
                  {Object.keys(kpi)
                  .filter(i => 
                      i === 'q1'  ||
                      i === 'q2'  ||
                      i === 'q3'  ||
                      i === 'q4'  ||
                      i === 'q5'  ||
                      i === 'cq1' ||
                      i === 'cq2' 
                  )
                  .map((num, i) => {
                    if (kpi[num] !== null && JSON.stringify(kpi[num]).includes([filter])) {
                      let data = []
                      Object.entries(kpi[num][filter]).forEach(([key, value]) => {
                        if (parseInt(key) >= parseInt(user.createdAt)) {
                          let month = key.substring(4,6) === '01'
                          ? 'Jan'
                          : key.substring(4,6) === '02'
                          ? 'Fev'
                          : key.substring(4,6) === '03'
                          ? 'Mar'
                          : key.substring(4,6) === '04'
                          ? 'Abr'
                          : key.substring(4,6) === '05'
                          ? 'Mai'
                          : key.substring(4,6) === '06'
                          ? 'Jun'
                          : key.substring(4,6) === '07'
                          ? 'Jul'
                          : key.substring(4,6) === '08'
                          ? 'Ago'
                          : key.substring(4,6) === '09'
                          ? 'Set'
                          : key.substring(4,6) === '10'
                          ? 'Out'
                          : key.substring(4,6) === '11'
                          ? 'Nov'
                          : 'Dez'
                          data.push({
                            value: value,
                            label: month+'/'+key.substring(2, 4)
                          });
                        }
                      });
                      const inovyoColors = ['#FFC400',
                                            '#008d3f',
                                            '#00A3dd',
                                            '#FF1928',
                                            '#8E0A14',
                                            '#593959',
                                            '#1BBF37',
                                            '#F64827',
                                            '#77429D',
                                            '#FFA300',
                                            '#9a2376'];
                      const finalColor = inovyoColors[i];

                      // X scale point
                      const xDomain = data.map(item => item.label)
                      const xRange = [0, graphWidth]
                      const x = d3.scalePoint()
                        .domain(xDomain)
                        .range(xRange)
                        .padding(1)

                      // Y scale linear
                      const topValue = 5
                      const yDomain = [0, topValue]
                      const yRange = [0, graphHeight]
                      const y = d3.scaleLinear()
                        .domain(yDomain)
                        .range(yRange)

                      // top axis and middle axis
                      const middleValue = topValue / 2
                      return(
                        <Paper
                          style={{
                            marginTop: 36,
                            padding: 24
                          }}
                          key={num}>
                          <p style={{ fontSize: 16, marginTop: 0, fontWeight: 'bold', color: '#999', paddingBottom: 6 }}>{kpi[num]['label'].toUpperCase()}</p>
                            <svg width={width} height={SVGHeight+20}>
                              <g y={graphHeight + GRAPH_MARGIN}>
                                {/* top axis */}
                                <line
                                  x1={0}
                                  y1={10}
                                  x2={graphWidth}
                                  y2={10}
                                  stroke={colors.axis}
                                  strokeDasharray={[3, 3]}
                                  strokeWidth={0.5}
                                />

                                {/* middle axis */}
                                <line
                                  x1={0}
                                  y1={y(middleValue) + 5}
                                  x2={graphWidth}
                                  y2={y(middleValue) + 5}
                                  stroke={colors.axis}
                                  strokeDasharray={[3, 3]}
                                  strokeWidth={0.5}
                                />

                                {/* bottom axis */}
                                <line
                                  x1={0}
                                  y1={y(topValue)}
                                  x2={graphWidth}
                                  y2={y(topValue)}
                                  stroke={colors.axis}
                                  strokeWidth={1}
                                />

                                {/* line */}
                                {data.map((item,index) => {
                                  return(
                                    <g key={'g'+item.label}>
                                      <line
                                        x1={x(item.label) - (GRAPH_BAR_WIDTH / 2) - 2.5}
                                        y1={(y(topValue) - y(item.value)) + 10}
                                        x2={x(data[index < (Object.entries(data).length - 1) ? index + 1 : index].label) - (GRAPH_BAR_WIDTH / 2) - 2.5}
                                        y2={(y(topValue) - y(data[index < (Object.entries(data).length - 1) ? index + 1 : index].value)) + 10}
                                        stroke={finalColor}
                                        strokeWidth='2'
                                      />
                                      <circle
                                        r={9}
                                        stroke={finalColor}
                                        fill={'white'}
                                        cx={x(item.label) - (GRAPH_BAR_WIDTH / 2) - 2.5}
                                        cy={(y(topValue) - y(item.value)) + 10}
                                      />
                                      <text
                                        key={'text'+item.label}
                                        fontSize={8}
                                        x={(x(item.label) - (GRAPH_BAR_WIDTH / 2)) - 2}
                                        y={(y(topValue) - y(item.value)) + 13}
                                        textAnchor='middle'>{item.value.toFixed(1)}</text>
                                    </g>
                                  )
                                })}

                                {/* labels */}
                                {data.map(item => (
                                  <text
                                  key={item.label}
                                  fill='#999'
                                  fontSize='10'
                                  x={x(item.label)}
                                  y={y(topValue) + 22}
                                  transform={`rotate(45,${x(item.label)},${y(topValue) + 22})`}
                                  textAnchor='middle'>{item.label}</text>
                                ))}
                              </g>
                            </svg>
                        </Paper>
                      )
                    }
                    return null;
                  })}
                </Grid>
              </Grid>
            </Container>
          </MuiThemeProvider>
        )
      }
    }
  }
}

export default connect(state => state)(Historic);