import React, { Component } from 'react';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles'
import {
  Container,
  Grid,
  Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFrown,
  faMeh,
  faSmile,
  faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

class NPS extends Component {

  render() {
    const { score, detail } = this.props;
    if (!detail) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container justify='center' alignItems='center' style={{ marginTop: 64 }}>
            <Grid item>
              <p style={{ textAlign: 'center' }} ><FontAwesomeIcon icon={faExclamationTriangle} color='#999' size='4x' /></p>
              <p style={{ textAlign: 'center', color: '#999' }} >Ainda não existe nenhuma resposta.</p>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    } else {
      return(
        <MuiThemeProvider theme={theme}>
          <Container className='heightPage'>
            <Grid container direction='row' justify='center' alignItems='center' spacing={4}>
              <Grid item sm={6} style={{ paddingRight: 8 }}>
                <Paper style={{
                  marginTop: 24,
                  backgroundColor: score >= 70 ? '#22aa58' : score >= 30 && score < 70 ? '#6ccbae' : score >= 0 && score < 30 ? '#ffca7a' : score < 0 ? '#dea2a2' : null,
                  height: 224,
                }}>
                  <Grid container direction='column' justify='center' alignItems='center'>
                    <p style={{ color: 'white', fontSize: 56, margin: 38 }}>{score}</p>
                    <p style={{ color: 'white', marginTop: 10, margin: 0 }}>NET PROMOTER SCORE</p>
                    <div style={{ borderBottomColor: 'white', borderBottomStyle: 'solid', borderBottomWidth: 1, width: '82%', marginTop: 12, marginBottom: 12 }} />
                    {score >= 70
                    ? <p style={{ color: 'white', marginTop: 6, margin: 0 }}>Zona de Excelência</p>
                    : score >= 50 && score < 70
                    ? <p style={{ color: 'white', marginTop: 6, margin: 0 }}>Zona de Qualidade</p>
                    : score >= 0 && score < 50
                    ? <p style={{ color: 'white', marginTop: 6, margin: 0 }}>Zona de Aperfeiçoamento</p>
                    : score < 0
                    ? <p style={{ color: 'white', marginTop: 6, margin: 0 }}>Zona Crítica</p>
                    : null
                    }
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sm={6} style={{ paddingLeft: 8 }}>
                <Paper style={{ backgroundColor: '#6ccbae', marginTop: 24 }}>
                  <Grid container direction='row' justify='center' alignItems='center'>
                    <Grid item xs={3}>
                      <p style={{ textAlign: 'center'}}>
                        <FontAwesomeIcon icon={faSmile} size='2x' color='#00752e'/>
                      </p>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container direction='row'>
                        <Grid item xs={8}>
                          <p style={{ color: '#fff' }}>{detail['promoter']['total']} promotores</p> 
                        </Grid>
                        <Grid item xs={4} style={{ position: 'relative' }}>
                          <div className='badge'>
                          <p style={{
                            top: 3,
                            textAlign: 'center',
                            marginLeft: detail['promoter']['percentage'] < 10 ? 5 : 0,
                            fontSize: detail['promoter']['percentage'] > 99 ? 13 : 14,
                            color: '#00752e',
                            position: 'relative',
                            right: detail['promoter']['percentage'] > 99 ? 3 : 0
                          }}>{detail['promoter']['percentage']+'%'}</p> 
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper style={{ backgroundColor: '#ffca7a', marginTop: 16 }}>
                  <Grid container direction='row' justify='center' alignItems='center'>
                    <Grid item xs={3}>
                      <p style={{ textAlign: 'center'}}>
                        <FontAwesomeIcon icon={faMeh} size='2x' color='#f90'/>
                      </p>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container direction='row'>
                        <Grid item xs={8}>
                          <p style={{ color: '#fff' }}>{detail['neutral']['total']} neutros</p> 
                        </Grid>
                        <Grid item xs={4} style={{ position: 'relative' }}>
                          <div className='badge'>
                          <p style={{
                            top: 3,
                            textAlign: 'center',
                            marginLeft: detail['neutral']['percentage'] < 10 ? 5 : 0,
                            fontSize: detail['neutral']['percentage'] > 99 ? 13 : 14,
                            color: '#f90',
                            position: 'relative',
                            right: detail['neutral']['percentage'] > 99 ? 3 : 0
                          }}>{detail['neutral']['percentage']+'%'}</p> 
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper style={{ backgroundColor: '#dea2a2', marginTop: 16 }}>
                  <Grid container direction='row' justify='center' alignItems='center'>
                    <Grid item xs={3}>
                      <p style={{ textAlign: 'center'}}>
                        <FontAwesomeIcon icon={faFrown} size='2x' color='#c00'/>
                      </p>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container direction='row'>
                        <Grid item xs={8}>
                          <p style={{ color: '#fff' }}>{detail['detractor']['total']} detratores</p> 
                        </Grid>
                        <Grid item xs={4} style={{ position: 'relative' }}>
                          <div className='badge'>
                          <p style={{
                            top: 3,
                            textAlign: 'center',
                            marginLeft: detail['detractor']['percentage'] < 10 ? 5 : 0,
                            fontSize: detail['detractor']['percentage'] > 99 ? 13 : 14,
                            color: '#c00',
                            position: 'relative',
                            right: detail['detractor']['percentage'] > 99 ? 3 : 0
                          }}>{detail['detractor']['percentage']+'%'}</p> 
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Paper style={{
                margin: 0,
                marginLeft: 18,
                marginRight: 18,
                padding: 0,
                paddingLeft: 18,
                paddingRight: 18
              }}>
                <Grid item sm={12}>
                  <p style={{ fontSize: 16 }}>O NET PROMOTER SCORE (NPS) é classificado através de uma pergunta: "Em uma escala de 0 a 10, o quanto você indicaria nossa empresa para um amigo?".</p> 
                  <p style={{ textAlign: 'center' }}>
                    <img src={require('../assets/nps.png')} alt="Classificação das Notas" style={{ width: '60%' }} />
                  </p>
                  <p style={{ fontSize: 16 }}>A fórmula para chegar ao score final é simples:</p> 
                  <p style={{ textAlign: 'center' }}>
                    <img src={require('../assets/score-nps.png')} alt="Cálculo NPS" style={{ width: '60%' }} />
                  </p>
                  <p style={{ fontFamily: 'Aller Bold', marginBottom: 6, fontSize: 16 }}>Classificação:</p> 
                  <ul>
                    <li style={{ fontSize: 16 }}>Zona de Excelência: NPS entre 71 e 100</li>
                    <li style={{ fontSize: 16 }}>Zona de Qualidade: NPS entre 51 e 70</li>
                    <li style={{ fontSize: 16 }}>Zona de Aperfeiçoamento: NPS entre 1 e 50</li>
                    <li style={{ fontSize: 16 }}>Zona Crítica: NPS entre -100 e 0</li>
                  </ul>
                </Grid>
              </Paper>
            </Grid>
          </Container>
        </MuiThemeProvider>
      )
    }
  }
}

export default connect(state => state)(NPS);