import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Paper,
  Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import * as d3 from 'd3';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

class IndividualChart extends Component {
  render() {
    const { title, detail, factor } = this.props

    if (!detail) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container justify='center' alignItems='center' style={{ marginTop: 64 }}>
            <Grid item>
              <p style={{ textAlign: 'center' }} ><FontAwesomeIcon icon={faExclamationTriangle} color='#999' size='4x' /></p>
              <p style={{ textAlign: 'center', color: '#999' }} >Ainda não existe nenhuma resposta.</p>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    } else {
      const data = Object.keys(detail).map(n => {
        return {
          position: n.toLowerCase() === 'muito satisfeito'
            ? 1
            : n.toLowerCase() === 'satisfeito'
            ? 2
            : n.toLowerCase() === 'neutro'
            ? 3
            : n.toLowerCase() === 'insatisfeito'
            ? 4
            : n.toLowerCase() === 'muito insatisfeito'
            ? 5
            : null,
          color: n.toLowerCase() === 'muito satisfeito'
            ? '#4ec04e'
            : n.toLowerCase() === 'satisfeito'
            ? '#acc75d'
            : n.toLowerCase() === 'neutro'
            ? '#ffc84a'
            : n.toLowerCase() === 'insatisfeito'
            ? '#f48a48'
            : n.toLowerCase() === 'muito insatisfeito'
            ? '#eb4841'
            : null,
          label: n.toUpperCase(),
          value: parseInt(detail[n]['percentage']),
          count: parseInt(detail[n]['total'])
        }
      });

      let dataOrdered = data.slice(0);
      dataOrdered.sort((a,b) => {
        return a.position - b.position;
      });

      const width = document.getElementsByClassName('myContent')[0].clientWidth * 0.90;
      const xDomain = [0, d3.max(dataOrdered, d => d.value)];
      const x = d3.scaleLinear()
                .domain(xDomain)
                .range([0, (width-48)]);

      const dataFactor = factor ? Object.keys(factor).filter(item => item !== 'count').map(n => {
        return {
          label: n.toUpperCase(),
          value: parseInt(factor[n]['percentage']),
          count: parseInt(factor[n]['total'])
        }
      }) : null;
      let dataFactorOrdered = factor ? dataFactor.slice(0) : null;
      if (factor) {
        dataFactorOrdered.sort((a,b) => {
          return b.value - a.value;
        });
      }

      return(
        <MuiThemeProvider theme={theme}>
          <Container className='heightPage'>
            <Grid container direction='row' justify='center' alignItems='center' spacing={4}>
              <Grid item xs={12}>
                <Paper style={{
                  marginTop: 36,
                  padding: 24 }}>
                  <p style={{ fontSize: 28, margin: 0, marginBottom: 24 }}>{title}</p>
                  {dataOrdered.map((bar, index) => (
                  <div
                    key={index}
                    width='100%'>
                    <p>{bar.label}</p>
                    <Tooltip title={`Respostas: ${bar.count}`} arrow>
                      <svg width='100%' height='24'>
                        <g>
                          <rect
                            rx='12'
                            ry='12'
                            width={width-48}
                            height='24'
                            fill='#eee'
                          />
                          <rect
                            rx='12'
                            ry='12'
                            width={(bar.value) === 0 ? 12 : x(bar.value)}
                            height='24'
                            fill={bar.color}
                          />
                          <text
                            fill={(bar.value) >= 8 ? 'white' : '#444444'}
                            fontSize='18'
                            fontWeight='bold'
                            x={(bar.value) === 0 ? 30 : (bar.value) >= 8 ? x(bar.value) - 28 : x(bar.value) + 20}
                            y='18'
                            textAnchor='middle'
                          >{bar.value+'%'}</text>
                        </g>
                      </svg>
                    </Tooltip>
                  </div>
                  ))}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 24 }}>
                  <p style={{ fontSize: 28, margin: 0 }}>Itens para Melhoria</p>
                  <p style={{ marginTop: 6, marginBottom: 36 }}>Respondido por {factor ? factor.count : 0} usuários que avaliaram {title} de 1 a 3 estrelas.</p>
                  {factor ?
                    dataFactorOrdered.map((bar, index) => (
                    <div
                      key={index}
                      width='100%'>
                      <p>{bar.label}</p>
                      <Tooltip title={`Respostas: ${bar.count}`} arrow>
                        <svg
                            width='100%'
                            height='24'
                        >
                          <g>
                            <rect
                              rx='12'
                              ry='12'
                              width='100%'
                              height='24'
                              fill='#eee'
                            />
                            <rect
                              rx='12'
                              ry='12'
                              width={bar.value >= 5 ? bar.value+'%' : 4+'%'}
                              height='24'
                              fill='#9a2376'
                            />
                            <text
                              fill={bar.value >= 15 ? 'white' : '#444444'}
                              fontSize='18'
                              fontWeight='bold'
                              x={bar.value >= 15 ? (bar.value - 4)+'%' : (bar.value + 5)+'%'}
                              y='18'
                              textAnchor='middle'
                            >{bar.value+'%'}</text>
                          </g>
                        </svg>
                      </Tooltip>
                    </div>
                  ))
                :
                  <Grid container justify='center' alignItems='center'>
                    <Grid item>
                      <p style={{ textAlign: 'center' }} ><FontAwesomeIcon icon={faExclamationTriangle} color='#999' size='4x' /></p>
                      <p style={{ textAlign: 'center', color: '#999' }} >Ainda não existe nenhuma resposta.</p>
                    </Grid>
                  </Grid>
                }
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </MuiThemeProvider>
      )
    }
  }
}

export default connect(state => state)(IndividualChart);