// eslint-disable
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    clientId
    name
    createdAt
    subItem
    tableFrequency
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    clientId
    name
    createdAt
    subItem
    tableFrequency
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    clientId
    name
    createdAt
    subItem
    tableFrequency
  }
}
`;
export const createResponse = `mutation CreateResponse($input: CreateResponseInput!) {
  createResponse(input: $input) {
    id
    clientId
    responseDate
    responseId
    status
    name
    phone
    email
    nps
    q1
    tagQ1
    q2
    tagQ2
    q3
    tagQ3
    q4
    tagQ4
    q5
    tagQ5
    cq1
    tagCq1
    cq2
    tagCq2
    frequency
    opinion
    howKnew
    subItem
    favorite
  }
}
`;
export const updateResponse = `mutation UpdateResponse($input: UpdateResponseInput!) {
  updateResponse(input: $input) {
    id
    clientId
    responseDate
    responseId
    status
    name
    phone
    email
    nps
    q1
    tagQ1
    q2
    tagQ2
    q3
    tagQ3
    q4
    tagQ4
    q5
    tagQ5
    cq1
    tagCq1
    cq2
    tagCq2
    frequency
    opinion
    howKnew
    subItem
    favorite
  }
}
`;
export const deleteResponse = `mutation DeleteResponse($input: DeleteResponseInput!) {
  deleteResponse(input: $input) {
    id
    clientId
    responseDate
    responseId
    status
    name
    phone
    email
    nps
    q1
    tagQ1
    q2
    tagQ2
    q3
    tagQ3
    q4
    tagQ4
    q5
    tagQ5
    cq1
    tagCq1
    cq2
    tagCq2
    frequency
    opinion
    howKnew
    subItem
    favorite
  }
}
`;
export const createStat = `mutation CreateStat($input: CreateStatInput!) {
  createStat(input: $input) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
export const updateStat = `mutation UpdateStat($input: UpdateStatInput!) {
  updateStat(input: $input) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
export const deleteStat = `mutation DeleteStat($input: DeleteStatInput!) {
  deleteStat(input: $input) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
export const createHistoric = `mutation CreateHistoric($input: CreateHistoricInput!) {
  createHistoric(input: $input) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
export const updateHistoric = `mutation UpdateHistoric($input: UpdateHistoricInput!) {
  updateHistoric(input: $input) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
export const deleteHistoric = `mutation DeleteHistoric($input: DeleteHistoricInput!) {
  deleteHistoric(input: $input) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
