// eslint-disable
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    clientId
    name
    createdAt
    subItem
    tableFrequency
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientId
      name
      createdAt
      subItem
      tableFrequency
    }
    nextToken
  }
}
`;
export const getResponse = `query GetResponse($id: ID!) {
  getResponse(id: $id) {
    id
    clientId
    responseDate
    responseId
    status
    name
    phone
    email
    nps
    q1
    tagQ1
    q2
    tagQ2
    q3
    tagQ3
    q4
    tagQ4
    q5
    tagQ5
    cq1
    tagCq1
    cq2
    tagCq2
    frequency
    opinion
    howKnew
    subItem
    favorite
  }
}
`;
export const listResponses = `query ListResponses(
  $filter: ModelResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientId
      responseDate
      responseId
      status
      name
      phone
      email
      nps
      q1
      tagQ1
      q2
      tagQ2
      q3
      tagQ3
      q4
      tagQ4
      q5
      tagQ5
      cq1
      tagCq1
      cq2
      tagCq2
      frequency
      opinion
      howKnew
      subItem
      favorite
    }
    nextToken
  }
}
`;
export const getStat = `query GetStat($id: ID!) {
  getStat(id: $id) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
export const listStats = `query ListStats(
  $filter: ModelStatFilterInput
  $limit: Int
  $nextToken: String
) {
  listStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientId
      responses
      nps
      q1
      q2
      q3
      q4
      q5
      cq1
      cq2
    }
    nextToken
  }
}
`;
export const getHistoric = `query GetHistoric($id: ID!) {
  getHistoric(id: $id) {
    id
    clientId
    responses
    nps
    q1
    q2
    q3
    q4
    q5
    cq1
    cq2
  }
}
`;
export const listHistorics = `query ListHistorics(
  $filter: ModelHistoricFilterInput
  $limit: Int
  $nextToken: String
) {
  listHistorics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientId
      responses
      nps
      q1
      q2
      q3
      q4
      q5
      cq1
      cq2
    }
    nextToken
  }
}
`;
