import React from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import '../App.css';
import Login from './Login';
import Home from './Home';
import Amplify from 'aws-amplify';
import AWSConfig from '../aws-exports';

Amplify.configure(AWSConfig);

ReactGA.initialize('UA-140591748-3');

function App() {
  return (
    <div>
      <Route exact path='/' component={Login} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/visao-geral' component={Home} />
    </div>
  );
}

export default App;