import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import {
  API,
  graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faUser,
  faStar } from '@fortawesome/free-solid-svg-icons';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress } from '@material-ui/core';
import {
  filterResponses,
  getNewResponses,
  responsesReady } from '../actions';
import * as queries from '../graphql/queries';
import {
  capitalLetter,
  formatDate } from '../utils/helper';
import IndividualResponse from './IndividualResponse';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

const filteItems = [
  {
    label: 'Todos',
    value: 0,
  },
  {
    label: 'Detratores',
    value: 1,
  },
  {
    label: 'Neutros',
    value: 2,
  },
  {
    label: 'Promotores',
    value: 3,
  },
];

class Responses extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      firstTextInput: null,
      favNps: null,
      lastTextInput: null,
    };
  };

  state = {
    checkFilter: [null,0,1,2,3,4,5,6,7,8,9,10],
    data: this.props.responses.individual,
    isLoaded: false,
    filterNps: this.props.responses.filter,
    responseDetail: [],
    isFavorite: false,
    render: false,
    showFavorites: false
  };

  componentDidMount() {
    const { user, filter } = this.props;

    this.fetchResponses(user.clientId, filter);
  }

  fetchResponses = (id, filter) => {
    const { dispatch } = this.props;
    API.graphql(graphqlOperation(queries.listResponses, {
      filter: {
        clientId: {
          eq: id
        },
        subItem: {
          contains: filter
        }
      },
      limit: 1000
    }))
    .then( (res) => {
      this.setState({
        nextToken: res.data.listResponses.nextToken,
        existToken: res.data.listResponses.nextToken ? true : false,
        myResponses: res.data.listResponses.items
      }, () => {
        if (!this.state.existToken) {
          const data = Object.keys(this.state.myResponses)
          .map(n => this.state.myResponses[n])

          dispatch(getNewResponses(data));
          dispatch(responsesReady());
          this.setState({
            isLoaded: true,
            data: data
          })
          console.log('responses is stored');
        }
      });
    })
    .then(() => {
      if (this.state.existToken) {
        this.fetchResponsesToken(id, filter, this.state.nextToken);
      }
    })
    .catch( (err) => {
      this.setState({ isResponsesLoading: false });
      console.log('responses error: ' + JSON.parse(err));
    });
  };

  fetchResponsesToken = (id, filter, token) => {
    const { dispatch } = this.props;
    API.graphql(graphqlOperation(queries.listResponses, {
      filter: {
        clientId: {
          eq: id
        },
        subItem: {
          contains: filter
        }
      },
      limit: 1000,
      nextToken: token
    }))
    .then( (res) => {
      this.setState({
        nextToken: res.data.listResponses.nextToken,
        existToken: res.data.listResponses.nextToken ? true : false,
        myResponses: this.state.myResponses.concat(res.data.listResponses.items)
      }, () => {
        if (!this.state.existToken) {
          const data = Object.keys(this.state.myResponses)
          .map(n => this.state.myResponses[n])

          dispatch(getNewResponses(data));
          dispatch(responsesReady());
          this.setState({
            isLoaded: true,
            data: data
          })
          console.log('responses is stored');
        }
      });
    })
    .then(() => {
      if (this.state.existToken) {
        this.fetchResponsesToken(id, filter, this.state.nextToken);
      }
    })
    .catch( (err) => {
      this.setState({ isResponsesLoading: false });
      console.log('responses error: ' + JSON.parse(err));
    });
  };

  checkFilter = (str) => {
    const { filter } = this.props;

    let data = Object.keys(this.props.responses.individual)
              .filter(i => this.props.responses.individual[i].subItem.includes(filter))
              .map(n => this.props.responses.individual[n])
              .sort(function(a,b){
                return new Date(b.responseDate) - new Date(a.responseDate);
              });

    let newDisplay = [];

    str === 'Detratores'
    ? this.setState({ checkFilter: [0,1,2,3,4,5,6] },
      () => {
        newDisplay = _.filter(data, item => this.state.checkFilter.includes(item.nps) && item.subItem.includes(filter));
        this.setState({ data: newDisplay });
      })
    : str === 'Neutros'
    ? this.setState({ checkFilter: [7,8] },
      () => {
        newDisplay = _.filter(data, item => this.state.checkFilter.includes(item.nps) && item.subItem.includes(this.props.filter));
        this.setState({ data: newDisplay });
      })
    : str === 'Promotores'
    ? this.setState({ checkFilter: [9,10] },
      () => {
        newDisplay = _.filter(data, item => this.state.checkFilter.includes(item.nps) && item.subItem.includes(this.props.filter));
        this.setState({ data: newDisplay });
      })
    : this.setState({ data: data });
    ReactGA.event({
      category: 'filters',
      action: 'nps',
      label: str
    });
  }

  handleChildFavorite = (id) => {
    const { filter } = this.props;
    let responseFav = Object.keys(this.props.responses.individual)
               .filter(i => this.props.responses.individual[i].id === id && this.props.responses.individual[i].subItem.includes(filter))
               .map(item => this.props.responses.individual[item]);

    responseFav[0].favorite = !responseFav[0].favorite;

    let responsesFav = Object.keys(this.props.responses.individual)
               .filter(i => this.props.responses.individual[i].id !== id && this.props.responses.individual[i].subItem.includes(filter))
               .map(item => this.props.responses.individual[item]);

    this.setState({ 'data': [...responsesFav, responseFav[0]], 'showFavorites': false, 'filterNps': 'Todos' })
  }

  toggleChecked = () => {
    const { responses, filter } = this.props;
    const { showFavorites } = this.state;
    let data = []
    if (!showFavorites) {
      data = Object.keys(responses.individual)
                .filter(i => responses.individual[i].favorite === true && responses.individual[i].subItem.includes(filter))
                .map(n => responses.individual[n])
                .sort(function(a,b){
                  return new Date(b.responseDate) - new Date(a.responseDate);
                });
    } else {
      data = Object.keys(responses.individual)
                .filter(i => responses.individual[i].subItem.includes(filter))
                .map(n => responses.individual[n])
                .sort(function(a,b){
                  return new Date(b.responseDate) - new Date(a.responseDate);
                });
    }
    this.setState({ 'data': data, 'showFavorites': !showFavorites, 'filterNps': 'Todos' })
    ReactGA.event({
      category: 'filters',
      action: 'favorite',
      label: showFavorites ? 'true' : 'false'
    });
  }

  handleClickComponent = (response, favorite, e) => {
    let pagePath = response
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/' '/g, '-')
    .replace(/\//g, '-')
    .toLowerCase();
    
    ReactGA.pageview(window.location.pathname + '/respostas/' + pagePath );
    ReactGA.set({ page: window.location.pathname + '/respostas/' + pagePath });
    this.setState({render: false, responseDetail: response, isFavorite: favorite }, () => {
      this.setState({ render: true });
    });
  }
  render() {
    const { isLoaded, responseDetail, isFavorite } = this.state;

    if (!isLoaded) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100vh' }}>
            <Grid item>
              <CircularProgress color='primary' />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    } else {
      const { data, showFavorites } = this.state;
      const { user, responses, filter } = this.props;

      return(
        <MuiThemeProvider theme={theme}>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
            <Grid item xs={6} className='myResponses heightPage'>
              <FormControl variant='outlined' className='selection'>
                <p style={{ margin: 2, marginBottom: 9 }}>Selecione uma classificação de NPS abaixo:</p>
                <Select
                  native
                  value={this.state.filterNps}
                  onChange={ event => {
                    this.setState({ filterNps: event.target.value });
                    this.checkFilter(event.target.value);
                    this.props.dispatch(filterResponses(event.target.value));
                  }}>
                  {filteItems.map( i => <option key={i.value}>{i.label}</option> )}
                </Select>
              </FormControl>
              <FormGroup style={{ marginLeft: 18 }}>
                <FormControlLabel
                  control={<Switch checked={showFavorites} onChange={this.toggleChecked} />}
                  label='Mostrar somente favoritos'
                />
              </FormGroup>
              <p style={{ marginBottom: 6, textAlign: 'center', fontSize: 12, color: '#999' }}>
                Mostrando {Object.keys(data).length} de {Object.keys(responses.individual).filter(i => responses.individual[i].subItem.includes(filter)).length} respostas totais.
              </p>
              {Object.keys(data).length === 0 ?
                <Grid container justify='center' alignItems='center' style={{ marginTop: 64 }}>
                  <Grid item>
                    <p style={{ textAlign: 'center' }} ><FontAwesomeIcon icon={faExclamationTriangle} color='#999' size='4x' /></p>
                    <p style={{ textAlign: 'center', color: '#999' }} >Ainda não existe nenhuma resposta.</p>
                  </Grid>
                </Grid>
              :
                <Grid item>
                  <List component='nav' aria-label='main'>
                    {data.sort(function(a,b){
                        return new Date(b.responseDate) - new Date(a.responseDate);
                      }).map(item => { 
                      const userFrequency = user.tableFrequency.filter(i => item.frequency === i[2])
                      const restaurant = item.subItem;
                      const color =
                          item.nps === null ? '#9a2376' :
                          item.nps <= 6 ? '#dea2a2' :
                          item.nps >= 9 ? '#6ccbae' : '#ffca7a';
                      return(
                        <ListItem
                          key={item.id}
                          button
                          onClick={this.handleClickComponent.bind(this, item.id, item.favorite)}
                          style={{ borderTopWidth: 1, borderTopColor: '#e5e5e5', borderTopStyle: 'solid' }}>
                          <ListItemAvatar>
                            <Avatar style={{ backgroundColor: color }}>
                              <FontAwesomeIcon icon={faUser} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <p style={{ margin: 0, marginBottom: 6 }}>
                              {item.name
                                ? `${capitalLetter(item.name)} `
                                : `Não informado`
                              }
                              {item.favorite
                                ? <Typography component='span'><FontAwesomeIcon icon={faStar} color='#ffc400' /></Typography>
                                : ''
                              }
                              </p>
                            }
                            secondary={
                              <div>
                                <p style={{ margin: 0, fontStyle: 'italic', color: '#666'}}>
                                  {Object.keys(restaurant).length > 1
                                    ? Object.keys(restaurant)
                                      .filter(i => restaurant[i] !== 'Todos')
                                      .map( item => restaurant[item]+' - ' )
                                    : ''
                                  }
                                  {userFrequency.length > 0
                                    ? userFrequency[0][1]
                                    : 'Sem classificação'
                                  }
                                </p>
                                <p style={{ margin: 0, fontSize: 12, color: '#666'}}>Respondido em {formatDate(item.responseDate)}</p>
                              </div>
                            } />
                        </ListItem>
                      )}
                    )}
                  </List>
                </Grid>
              }
            </Grid>
            <Grid item xs={6}>
              {this.state.render &&
                <IndividualResponse
                  item={responseDetail}
                  favorite={isFavorite}
                  onChildFavorite={this.handleChildFavorite} />
              }
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    }
  }
}

export default connect(state => state)(Responses);