import React, { Component } from 'react';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Fab,
  TextField,
  Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

export default class ChangePassword extends Component {
  state = {
    step: 0,
    oldPassword: '',
    errorOldPass: true,
    passOldError: '',
    newPassword: '',
    errorNewPass: true,
    passNewError: '',
    isChangeLoading: false
  }

  handleChangePass = () => {
    const { newPassword, oldPassword } = this.state;
    this.setState({ isChangeLoading: true });

    Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
      this.setState({ step: 1, isChangeLoading: false });
      console.log('successfully changed password');
    })
    .catch(err => {
      if (err.message.includes('Incorrect username or password.')) {
        this.setState({ errorOldPass: false, passOldError: 'A senha informada está incorreta' });
      } else if (err.message.includes('Password not long enough')) {
        this.setState({ errorNewPass: false, passNewError: 'A senha deve conter no mínimo 8 dígitos e ter ao menos 1 letra maiúscula, minúscula e número' });
      }
      this.setState({ isChangeLoading: false });
      console.log('error on change pass: '+JSON.stringify(err));
    })
  }

  handleOldPass = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ errorOldPass: false, passOldError: 'Campo obrigatório' });
    }
    this.setState({ oldPassword: query, errorOldPass: true, passOldError: '' });
  }

  handleNewPass = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ errorNewPass: false, passNewError: 'Campo obrigatório' });
    }
    this.setState({ newPassword: query, errorNewPass: true, passNewError: '' });
  }

  render() {
    const { step, errorOldPass, passOldError, errorNewPass, passNewError, isChangeLoading } = this.state

    return (
      <MuiThemeProvider theme={theme}>
        <Container maxWidth='xs'>
          {step === 0
          ? <Grid container direction='row' justify='center' alignItems='center' style={{ padding: 16 }}>
            <Grid item xs={12}>
                <TextField
                  fullWidth
                  error={!errorOldPass}
                  label='Senha atual'
                  variant='outlined'
                  value={this.state.oldPassword}
                  onChange={this.handleOldPass}
                  helperText={passOldError}
                  style={{ width: '100%', marginBottom: 16 }}
                  type='password' />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  error={!errorNewPass}
                  label='Nova senha'
                  variant='outlined'
                  value={this.state.newPassword}
                  onChange={this.handleNewPass}
                  helperText={passNewError}
                  style={{ width: '100%', marginBottom: 16 }}
                  type='password' />
              </Grid>
              <Grid item xs={12}>
                <Fab
                  style={{ width: '100%' }}
                  variant='extended'
                  color='primary'
                  aria-label='change'
                  onClick={ this.handleChangePass }>
                    ALTERAR
                    {isChangeLoading &&
                      <FontAwesomeIcon icon={faCircleNotch} size='2x' color='#fff' spin style={{ position: 'absolute', right: 12 }} />
                    }
                </Fab>
              </Grid>
            </Grid>
          : <Grid container direction='column' justify='center' alignItems='center' style={{ padding: 16 }}>
              <Grid item xs={12}>
                <FontAwesomeIcon icon={faCheckCircle} size='3x' color='#0c0' style={{ textAlign: 'center', marginBottom: 12 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography component='p' style={{textAlign: 'center'}}>Senha alterada com sucesso, clique no botão abaixo para fechar.</Typography>
              </Grid>
            </Grid>
          }
        </Container>
      </MuiThemeProvider>
    );
  }
}