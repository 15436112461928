/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "oqueachoudesktop-20191128163104-hostingbucket-test",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d314d7z3imnqxv.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:eb1da135-3558-4a36-ad16-0096042f8b6a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FgourkB5i",
    "aws_user_pools_web_client_id": "17kp0860k9nm46dq0er9ea11mj",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ydeuehkhuzadjlwkdqmi2gxlzu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "qse2xpf3ynbivdjbatylq2casi"
};


export default awsmobile;
