import React, { Component } from 'react';
import ReactGA from 'react-ga';
import {
  Auth,
  API,
  graphqlOperation } from 'aws-amplify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faSignOutAlt,
  faChevronRight,
  faCommentAlt,
  faKey,
  faEllipsisV,
  faFrown,
  faMeh,
  faSmile,
  faUser,
  faChartLine } from '@fortawesome/free-solid-svg-icons';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  Grid,
  Fab,
  FormControl,
  Select,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {
  getUserInfo,
  setStatistics } from '../actions';
import * as queries from '../graphql/queries';
import ChangePassword from './ChangePassword';
import NPS from './NPS';
import IndividualChart from './IndividualChart';
import Responses from './Responses';
import Historic from './Historic';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

class Home extends Component {
  state = {
    isLoaded: false,
    checkFilter: this.props.user.subItem,
    data: this.props.stats,
    filterRest: 'Todos',
    anchorEl: null,
    openChangePass: false,
    render: '',
    title: '',
    indexKpi: '',
    renderNow: false
  }

  async componentDidMount() {
    const { dispatch } = this.props
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.set({ page: window.location.pathname });

    await Auth.currentAuthenticatedUser()
    .then(async user => {
      const userInfo = await API.graphql(graphqlOperation(queries.getUser, {id: user.username}))
      dispatch(
        getUserInfo(
          userInfo.data.getUser.id,
          userInfo.data.getUser.clientId,
          userInfo.data.getUser.name,
          userInfo.data.getUser.createdAt,
          userInfo.data.getUser.subItem,
          userInfo.data.getUser.tableFrequency
        )
      )
      ReactGA.set({ userId: userInfo.data.getUser.clientId+'_'+userInfo.data.getUser.id.split('@')[0].substr(0,3).toUpperCase()+'_'+userInfo.data.getUser.id.split('@')[0].substr(userInfo.data.getUser.id.split('@')[0].length - 3).toUpperCase() });
      console.log('user is stored');
      this.getClientStats()
      .then(() => {
        this.setState({ isLoaded: true });
        console.log('stats is stored');
      })
      .catch(err => console.log('error on stats: ', JSON.stringify(err)));
    })
    .catch(err => console.log('error on user: ', JSON.stringify(err)));
  }

  async getClientStats() {
    const { user } = this.props
    const userStats = await API.graphql(graphqlOperation(queries.listStats, {
      filter: {
        clientId: {
          eq: user.clientId
        }
      },
      limit: 50,
    }))
    {Object.keys(userStats.data.listStats.items).map(num => {
      this.props.dispatch(
        setStatistics(
          userStats.data.listStats.items[num].responses,
          userStats.data.listStats.items[num].nps,
          userStats.data.listStats.items[num].q1,
          userStats.data.listStats.items[num].q2,
          userStats.data.listStats.items[num].q3,
          userStats.data.listStats.items[num].q4,
          userStats.data.listStats.items[num].q5,
          userStats.data.listStats.items[num].cq1,
          userStats.data.listStats.items[num].cq2,
          userStats.data.listStats.items[num].id
        )
      )
      return null;
    })}
  }

  _changePass = () => {
    this.setState({ openChangePass: !this.state.openChangePass });
    ReactGA.modalview('/alterar-a-senha');
  }

  _signOut = () => {
    ReactGA.event({
      category: 'account',
      action: 'logout'
    });
    Auth.signOut()
    .then( () => {
      this.props.history.push(`/login`)
      console.log('sign out');
    })
    .catch(err => console.log('sign out error: ', JSON.stringify(err)));
  }

  handleClick = event => {
    ReactGA.event({
      category: 'account',
      action: 'menu'
    });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClickComponent(compName, title, index, e){
    let pagePath = title
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/' '/g, '-')
    .replace(/\//g, '-')
    .toLowerCase();
    
    ReactGA.pageview(window.location.pathname + '/' + pagePath );
    ReactGA.set({ page: window.location.pathname + '/' + pagePath });
    this.setState({ render: compName, title: title, indexKpi: index });
    compName === 'responses' && this.setState({ renderNow: false }, () => this.setState({ renderNow: !this.state.renderNow}));
  }

  render() {
    const { user, stats } = this.props;
    const { isLoaded, anchorEl, openChangePass, filterRest } = this.state;

    if (!isLoaded) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100vh' }}>
            <Grid item>
              <CircularProgress color='primary' />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    } else if (!Object.keys(stats).length > 0) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container justify='center' alignItems='center' style={{ marginTop: 64 }}>
            <Grid item>
              <p style={{ textAlign: 'center' }} ><FontAwesomeIcon icon={faExclamationTriangle} color='#999' size='4x' /></p>
              <p style={{ textAlign: 'center', color: '#999' }} >Ainda não existe nenhuma resposta.</p>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    } else {
      const nps = Object.keys(stats).map(num => stats[num].nps[filterRest])[0];
      const kpi = Object.keys(stats).map(num => stats[num])[0];
      const score = nps ? nps['promoter']['percentage'] - nps['detractor']['percentage'] : '';
      const filterEst = Object.keys(user.subItem).map(i => user.subItem[i]).sort().filter(j => j !== 'Todos');
      filterEst.unshift('Todos');

      return(
        <MuiThemeProvider theme={theme}>
          <AppBar>
            <Toolbar>
              <Typography variant='h6' noWrap>O QUE ACHOU</Typography>
              <IconButton
                color='inherit'
                edge='end'
                aria-label='account'
                aria-controls='account-menu'
                aria-haspopup='true'
                onClick={this.handleClick}
                style={{ position: 'absolute', right: 18, top: 8, width: 48, height: 48 }}>
                <FontAwesomeIcon icon={faEllipsisV} color='#fff' />
              </IconButton>
              <Menu
                keepMounted
                id='account-menu'
                aria-haspopup='true'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuList>
                  <MenuItem>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUser} />
                    </ListItemIcon>
                    <Typography component='p'>{user.name}</Typography>
                  </MenuItem>
                  <MenuItem onClick={this._changePass}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faKey} />
                    </ListItemIcon>
                    <Typography component='p'>Alterar senha</Typography>
                  </MenuItem>
                  <MenuItem onClick={this._signOut}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </ListItemIcon>
                    <Typography component='p'>Sair</Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Toolbar>
          </AppBar>
          <Dialog
            fullWidth
            maxWidth='xs'
            open={openChangePass}
            onClose={this._changePass}
            aria-labelledby='alert-dialog-title'
          >
            <DialogTitle id='alert-dialog-title'>Alterar Senha</DialogTitle>
            <DialogContent dividers>
              <ChangePassword />
            </DialogContent>
            <DialogActions>
              <Button onClick={this._changePass} color='primary'>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container justify='center' style={{ marginTop: 64 }}>
            <Grid item sm={4} className='myMenu heightPage'>
              {Object.keys(user.subItem).length > 2 &&
                <FormControl variant='outlined' className='selection'>
                  <Typography component='p' style={{ margin: 0, marginBottom: 6 }}>Selecione um estabelecimento abaixo:</Typography>
                  <Select
                    native
                    value={filterRest}
                    onChange={ event => {
                      ReactGA.event({
                        category: 'filters',
                        action: 'establishment',
                        label: event.target.value
                      });
                      this.setState({ filterRest: event.target.value, renderNow: false }, () => this.setState({ renderNow: !this.state.renderNow}));
                    }}
                  >
                  {filterEst.map( i => {
                      return(
                      <option key={i}>{i}</option>
                    )})
                  }
                  </Select>
                </FormControl>
              }
              {Object.keys(stats)
              .filter(num => stats[num].nps)
              .map((num, i) => (
                <Grid container
                  key={num}
                  className='nps'>
                  <Grid item style={{ width: '100%' }}>
                    <Typography component='p' style={{textAlign: 'center', fontSize: 21, marginTop: 0, marginBottom: 6 }}>NET PROMOTER SCORE</Typography>
                    <Typography component='h6' style={{
                      margin: 4,
                      paddingBottom: 10,
                      textAlign: 'center',
                      color: score >= 70
                        ? '#00660b'
                        : score >= 30 && score < 70
                        ? '#00752e'
                        : score >= 0 && score < 30
                        ? '#f90' : score < 0
                        ? '#c00'
                        : null,
                      fontSize: 48
                    }}>{nps ? score : '-'}</Typography>
                      <Grid container justify='center' spacing={4}>
                        <Grid item xs={4}>
                          <Typography component='p' style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faFrown} color='#c00'/> <span style={{ fontSize: 21, fontFamily: 'Aller Bold' }}>{nps ? nps['detractor']['percentage']+'%' : '0%'}</span><br/>Detratores</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography component='p' style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faMeh} color='#f90'/> <span style={{ fontSize: 21, fontFamily: 'Aller Bold' }}>{nps ? nps['neutral']['percentage']+'%' : '0%'}</span><br/>Neutros</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography component='p' style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faSmile} color='#00660b'/> <span style={{ fontSize: 21, fontFamily: 'Aller Bold' }}>{nps ? nps['promoter']['percentage']+'%' : '0%'}</span><br/>Promotores</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  {nps &&
                    <Fab
                      onClick={this.handleClickComponent.bind(this, 'nps', 'NPS')}
                      style={{ width: '100%', marginTop: 16 }}
                      variant='extended'
                      color='primary'
                      aria-label='add'>VER MAIS INFORMAÇÕES</Fab>
                  }
                </Grid>
              ))}
              <List component='nav' aria-label='main'>
                {Object.keys(kpi).filter(num => 
                  num === 'q1'  ||
                  num === 'q2'  ||
                  num === 'q3'  ||
                  num === 'q4'  ||
                  num === 'q5'  ||
                  num === 'cq1' ||
                  num === 'cq2' 
                )
                .map((num, i) => {
                  if (kpi[num] !== null) {
                    return(
                      <ListItem
                        button
                        style={{ borderTopWidth: 1, borderTopColor: '#e5e5e5', borderTopStyle: 'solid' }}
                        key={'kpi'+num}
                        onClick={this.handleClickComponent.bind(this, 'individual', kpi[num]['label'], num)}>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='flex-start'
                          spacing={4}>
                            <Grid item>
                              <ListItemText primary={kpi[num]['label']} />
                              <Rating
                                readOnly
                                value={kpi[num]['rate'][filterRest] ? kpi[num]['rate'][filterRest] : 0}
                                precision={0.1}
                                size='large' />
                              <Typography component='p' style={{ fontSize: 18, marginLeft: 8, marginTop: 2 }}><strong>{kpi[num]['rate'][filterRest] ? kpi[num]['rate'][filterRest] : '-'}</strong>/5 <span style={{ fontSize: 12, marginLeft: 4, marginTop: 6, color: '#666' }}>({kpi[num]['count'][filterRest] ? kpi[num]['count'][filterRest] : 0} respostas)</span></Typography>
                            </Grid>
                        </Grid>
                        <FontAwesomeIcon icon={faChevronRight} color='#ccc' />
                      </ListItem>
                    )
                  }
                  return null;
                })}
                {Object.keys(kpi).filter(num => 
                  num === 'responses'
                )
                .map((num, i) => (
                <ListItem
                  button
                  style={{ borderTopWidth: 1, borderTopColor: '#e5e5e5', borderTopStyle: 'solid' }}
                  key={'resp'+num}
                  onClick={this.handleClickComponent.bind(this, 'responses', 'Respostas')}>
                    <Grid container justify='center' spacing={4}>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faCommentAlt} size='3x' color='#9a2376' style={{ marginTop: 18 }}/>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component='p' style={{ fontSize: 36, margin: 0, padding: 0 }}>{kpi[num][filterRest] ? kpi[num][filterRest] : 0}</Typography>
                        <ListItemText primary={
                          kpi[num][filterRest] !== 1 
                          ? 'Respostas'
                          : 'Resposta'
                        }/>
                      </Grid>
                    </Grid>
                    <FontAwesomeIcon icon={faChevronRight} color='#ccc' />
                  </ListItem>
                ))}
                <ListItem
                  button
                  style={{ borderTopWidth: 1, borderTopColor: '#e5e5e5', borderTopStyle: 'solid' }}
                  key='hist'
                  onClick={this.handleClickComponent.bind(this, 'historic', 'Histórico')}>
                    <Grid container justify='center' spacing={4}>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faChartLine} size='3x' color='#9a2376' style={{ marginTop: 18 }}/>
                      </Grid>
                      <Grid item xs={10}>
                        <ListItemText primary={
                          <Typography component='p' style={{ fontSize: 21, margin: 0, marginTop: 23, padding: 0 }}>Histórico</Typography>
                        }/>
                      </Grid>
                    </Grid>
                    <FontAwesomeIcon icon={faChevronRight} color='#ccc' />
                  </ListItem>
              </List>
            </Grid>
            <Grid item sm={8} className='myContent'>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'>
                {this.state.render === '' ?
                  <Grid item>
                    <p style={{ textAlign: 'center' }}><img src={require('../assets/oqueachou.png')} style={{margin: 20, width: 230}} alt='O Que Achou' /></p>
                    <p>Selecione um item ao lado para ver mais informações.</p>
                  </Grid>
                : this.state.render === 'nps' ?
                  <NPS
                    score={score}
                    detail={nps}
                    title={this.state.title} />
                : this.state.render === 'individual' ?
                  <IndividualChart
                    detail={kpi[this.state.indexKpi]['details'][filterRest]}
                    factor={kpi[this.state.indexKpi]['factors'][filterRest]}
                    title={this.state.title} />
                : this.state.render === 'historic' ?
                  <Historic
                    filter={filterRest} />
                : this.state.renderNow && this.state.render === 'responses' ?
                  <Responses
                    filter={filterRest} />
                : null}
              </Grid>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      );
    }
  }
}
export default connect(state => state)(Home);