import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Auth } from 'aws-amplify';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Fab,
  Button,
  Link,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from './ForgotPassword';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

export default class Login extends Component {
  state = {
    isConnected: true,
    isLoading: true,
    email: '',
    password: '',
    isSignInLoading: false,
    showBar: false,
    errorPass: true,
    passError: '',
    errorEmail: true,
    emailError: '',
    openForgotPass: false
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.set({ page: window.location.pathname });
    try {
      await Auth.currentAuthenticatedUser();
      this.setState({ isLoading: false });
      this.props.history.push(`/visao-geral`)
    } catch(err) {
      this.setState({ isLoading: false });
      console.log('status:', JSON.stringify(err));
    }
  }

  handleSignIn = (event) => {
    event.preventDefault()
    const { email, password } = this.state
    this.setState({ isSignInLoading: true })
    ReactGA.event({
      category: 'account',
      action: 'login'
    });

    Auth.signIn(email, password)
    .then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const newPassword = password;
        Auth.completeNewPassword(user, newPassword)
        .then(user => {
          this.setState({ isSignInLoading: false });
          this.props.history.push(`/visao-geral`)
        })
        .catch(err => {
          this.setState({ isSignInLoading: false });
          console.log('New Pass Error: ', JSON.stringify(err));
        })
      } else {
        this.setState({ isSignInLoading: false });
        this.props.history.push(`/visao-geral`)
      }
    })
    .catch(err => {
      this.setState({ isSignInLoading: false });
      console.log('Sigin Error: ', JSON.stringify(err));
      if (err.code === 'UserNotFoundException') {
        this.setState({ errorEmail: false, emailError: 'O e-mail inserido não está cadastrado'})
      } else if (err === 'Username cannot be empty') {
        this.setState({ errorEmail: false, emailError: 'O e-mail deve ser preenchido'})
      } else if (err.code === 'NotAuthorizedException') {
        this.setState({ errorPass: false, passError: 'A senha informada está incorreta'})
      } else if (err === 'Password cannot be empty' || err.code === 'UnexpectedLambdaException') {
        this.setState({ errorPass: false, passError: 'A senha deve ser preenchida'})
      }
    })
  }

  _forgotPass = () => {
    this.setState({ openForgotPass: !this.state.openForgotPass });
    ReactGA.modalview('/esqueceu-a-senha');
  }

  handleEmail = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ errorEmail: false, emailError: 'Campo obrigatório' });
    }
    this.setState({ email: query, errorEmail: true, emailError: '' });
  }

  validateEmail = (event) => {
    const query = event.target.value;

    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(query) === true) {
      this.setState({ errorEmail: true });
      return false
    } else if (!query) {
      this.setState({ errorEmail: true });
    } else {
      this.setState({ errorEmail: false, emailError: 'Endereço de e-mail inválido' });
    }
  }

  handlePass = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ errorPass: false, passError: 'Campo obrigatório' });
    }
    this.setState({ password: query, errorPass: true, passError: '' });
  }

  render() {
    const { isLoading, isSignInLoading, emailError, errorEmail, passError, errorPass, openForgotPass } = this.state;

    if (isLoading) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100vh' }}>
            <Grid item>
              <CircularProgress color='primary' />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    }
    return (
      <MuiThemeProvider theme={theme}>
        <Container maxWidth='xs'>
          <Grid container direction='row' justify='center' alignItems='center' spacing={4}>
            <Grid item xs={12}>
              <Typography component='p' style={{ textAlign: 'center' }}>
                <img style={{margin: 20, width: 180}} src={ require('../assets/oqueachou.png') } alt='O Que Achou' />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={this.handleSignIn} noValidate autoComplete='off'>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={!errorEmail}
                    label='E-mail'
                    variant='outlined'
                    value={this.state.email}
                    onChange={this.handleEmail}
                    onBlur={this.validateEmail}
                    helperText={emailError}
                    type='email' />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 16 }}>
                  <TextField
                    fullWidth
                    error={!errorPass}
                    label='Senha'
                    variant='outlined'
                    value={this.state.password}
                    onChange={this.handlePass}
                    helperText={passError}
                    type='password' />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 16 }}>
                  <Fab style={{ width: '100%' }} variant='extended' color='primary' aria-label='send' type='submit'>
                    ENTRAR
                    {isSignInLoading &&
                      <FontAwesomeIcon icon={faCircleNotch} size='2x' color='#fff' spin style={{ position: 'absolute', right: 12 }} />
                    }
                  </Fab>
                </Grid>
              </form>
              <Grid item xs={12} style={{ marginTop: 16 }}>
                <Typography component='p' style={{ textAlign: 'center' }}><Link style={{ color: '#9a2376', cursor: 'pointer' }} variant='extended' aria-label='forgot' onClick={this._forgotPass}>Esqueceu a senha?</Link></Typography>
              </Grid>
              <Dialog
                fullWidth
                maxWidth='xs'
                open={openForgotPass}
                onClose={this._forgotPass}
                aria-labelledby='alert-dialog-title'
              >
                <DialogTitle id='alert-dialog-title'>Esqueceu a Senha?</DialogTitle>
                <DialogContent dividers>
                  <ForgotPassword />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this._forgotPass} color='primary'>
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Container>
      </MuiThemeProvider>
    );
  }
}