export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_NEW_RESPONSES = 'GET_NEW_RESPONSES';
export const GET_ALL_RESPONSES = 'GET_ALL_RESPONSES';
export const FAVORITE_FLAG = 'FAVORITE_FLAG';
export const GET_HISTORIC = 'GET_HISTORIC';
export const RESPONSES_READY = 'RESPONSES_READY';
export const SET_FILTER = 'SET_FILTER';
export const GET_ALL_FAVORITES = 'GET_ALL_FAVORITES';
export const SAVE_INDIVIDUAL_FAVORITE = 'SAVE_INDIVIDUAL_FAVORITE'
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const FAVORITES_READY = 'FAVORITES_READY';

export function getUserInfo(id, clientId, name, createdAt, subItem, tableFrequency) {
  return {
    type: GET_USER_INFO,
    id,
    clientId,
    name,
    createdAt,
    subItem,
    tableFrequency
  }
}

export function setStatistics(responses, nps, q1, q2, q3, q4, q5, cq1, cq2, id) {
  return {
    type: GET_STATISTICS,
    responses,
    nps,
    q1,
    q2,
    q3,
    q4,
    q5,
    cq1,
    cq2,
    id
  }
}

export function setHistoric(responses, nps, q1, q2, q3, q4, q5, cq1, cq2, id) {
  return {
    type: GET_HISTORIC,
    responses,
    nps,
    q1,
    q2,
    q3,
    q4,
    q5,
    cq1,
    cq2,
    id
  }
}

export function getNewResponses(data) {
  return {
    type: GET_NEW_RESPONSES,
    data
  }
}

export function getAllResponses(data) {
  return {
    type: GET_ALL_RESPONSES,
    data
  }
}

export function favoriteFlag(id, data) {
  return {
    type: FAVORITE_FLAG,
    payload: data,
    id
  }
}

export function responsesReady() {
  return {
    type: RESPONSES_READY,
    payload: true
  }
}

export function filterResponses(str) {
  return {
    type: SET_FILTER,
    filter: str
  }
}

export function getAllFavorite(data) {
  return {
    type: GET_ALL_FAVORITES,
    data
  }
}

export function saveIndividualFavorite(data) {
  return {
    type: SAVE_INDIVIDUAL_FAVORITE,
    data
  }
}

export function removeFavorite(id) {
  return {
    type: REMOVE_FAVORITE,
    id
  }
}

export function favoritesReady() {
  return {
    type: FAVORITES_READY,
    payload: true
  }
}