import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import {
  API,
  graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  MuiThemeProvider,
  createMuiTheme } from '@material-ui/core/styles';
import {
  Paper,
  IconButton,
  Button,
  Grid,
  List,
  ListItem,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  CircularProgress } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {
  capitalLetter,
  formatDate,
  rateTransform } from '../utils/helper';
import {
  favoriteFlag,
  removeFavorite,
  saveIndividualFavorite } from '../actions';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a2376',
    },
  },
  typography: { useNextVariants: true },
})

class IndividualResponse extends Component {
  state = {
    statsId: null,
    isLoaded: false,
    modal: false,
    isFavorite: '#ccc',
    response: {}
  }


  async componentDidMount() {

    const { stats, item, favorite } = this.props;
    this.setState({
      statsId: Object.keys(stats),
      isFavorite: favorite ? '#ffc400' : '#ccc'
    })

    await API.graphql(graphqlOperation(queries.getResponse, { id: item }))
    .then( (res) => {
      this.setState({
        isLoaded: true,
        response: res.data.getResponse
      })
    })
    .catch( err => console.log('error in response: '+JSON.stringify(err)) );
  }

  setFavorite = () => {
    const { dispatch } = this.props;
    const { response } = this.state;

    if (response.favorite) {
      ReactGA.event({
        category: 'favorite',
        action: 'remove',
        label: response.id
      });
      API.graphql(graphqlOperation(mutations.updateResponse, {
        input: {
          id: response.id,
          favorite: false
        }
      }))
      let responseCopy = response;
      responseCopy.favorite = !response.favorite;
      this.setState({ isFavorite: '#ccc', response: responseCopy })
      this.submitToParent(response.id);
      dispatch(removeFavorite(response.id));
      dispatch(favoriteFlag(response.id, false));
      console.log('response removed from favorites');
    } else {
      ReactGA.event({
        category: 'favorite',
        action: 'add',
        label: response.id
      });
      API.graphql(graphqlOperation(mutations.updateResponse, {
        input: {
          id: response.id,
          favorite: true
        }
      } ))
      let responseCopy = response;
      responseCopy.favorite = !response.favorite;
      this.setState({ isFavorite: '#ffc400', response: responseCopy })
      this.submitToParent(response.id);
      dispatch(saveIndividualFavorite(response));
      dispatch(favoriteFlag(response.id, true));
      console.log('response added to favorites');
    }
  }

  submitToParent(id) {
    this.props.onChildFavorite(id);
  }

  toggleModal = () => {
    ReactGA.modalview('/tabela-frequencia');
    this.setState({ modal: !this.state.modal });
  }
  render() {
    const { user, stats } = this.props;
    const { isLoaded, response, statsId, isFavorite } = this.state;
    const arr = [];
    user.tableFrequency.map(item => {
      arr.push(item[3]);
      return null;
    })
    const showColumn = Array.from(new Set(arr)).length > 1 ? true : false;
    const userFrequency = user.tableFrequency.filter(i => response.frequency === i[2])

    if (!isLoaded) {
      return (
        <MuiThemeProvider theme={theme}>
          <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100vh' }}>
            <Grid item>
              <CircularProgress color='primary' />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )
    } else {
      return(
        <MuiThemeProvider theme={theme}>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
            <Grid item xs={12} className='userResponses heightPage'>
              <List>
                <ListItem>
                  <p className='responseTitle' style={{
                    fontSize: 21, 
                    marginTop: 20,
                    marginBottom: 20,
                    marginRight: 10
                  }}>
                    {response.name
                      ? `${capitalLetter(response.name)} `
                      : `Não informado`
                    }</p>
                  <IconButton
                    color='inherit'
                    edge='end'
                    onClick={this.setFavorite}>
                    <FontAwesomeIcon icon={faStar} color={isFavorite} />
                  </IconButton>
                </ListItem>
                <Divider />
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <p className='responseTitle'>Data da Resposta</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='responseUser'>{formatDate(response.responseDate)}</p>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <p className='responseTitle'>E-mail</p>
                    </Grid>
                    <Grid item xs={12}>
                    {response.email
                      ? <p className='responseUser'><a href={`mailto:${response.email}`}>{response.email}</a></p>
                      : <p className='responseUser'>Não informado</p>
                    }
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <p className='responseTitle'>Telefone</p>
                    </Grid>
                    <Grid item xs={12}>
                    {response.phone
                      ? <p className='responseUser'>{response.phone}</p>
                      : <p className='responseUser'>Não informado</p>
                    }
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <p className='responseTitle'>Classificação (NPS)</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='responseUser'>
                        {response.nps == null ? 'Não Informado'
                        : response.nps <= 6
                        ? 'Detrator ('+response.nps+')'
                        : response.nps >= 9
                        ? 'Promotor ('+response.nps+')'
                        : 'Neutro ('+response.nps+')'
                      }
                      </p>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                      <p className='responseTitle'>Classificação do Cliente</p>
                      <IconButton
                        color='inherit'
                        edge='end'
                        style={{ position: 'absolute', right: 12, top: -14 }}
                        onClick={this.toggleModal}>
                        <FontAwesomeIcon icon={faQuestionCircle} color='#333' />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='responseUser'>
                        {userFrequency.length > 0
                        ? userFrequency[0][1]
                        : 'Não informado'
                        }
                      </p>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                      <p className='responseTitle'>Como nos conheceu</p>
                    </Grid>
                    <Grid item xs={12}>
                      {response.howKnew
                        ? <p className='responseUser'>{response.howKnew}</p>
                        : <p className='responseUser'>Não informado</p>
                      }
                    </Grid>
                  </Grid>
                </ListItem>
                { response.q1 &&
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='responseTitle'>{stats[statsId].q1.label}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Rating
                          readOnly
                          value={rateTransform(response.q1)}
                          precision={1}
                          size='large' />
                        <Grid item xs={12}>
                          {response.tagQ1 && response.tagQ1.sort().map(n => 
                            <Chip label={n} color="primary" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
                { response.q2 &&
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='responseTitle'>{stats[statsId].q2.label}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Rating
                          readOnly
                          value={rateTransform(response.q2)}
                          precision={1}
                          size='large' />
                        <Grid item xs={12}>
                          {response.tagQ2 && response.tagQ2.sort().map(n => 
                            <Chip label={n} color="primary" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
                { response.q3 &&
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='responseTitle'>{stats[statsId].q3.label}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Rating
                          readOnly
                          value={rateTransform(response.q3)}
                          precision={1}
                          size='large' />
                        <Grid item xs={12}>
                          {response.tagQ3 && response.tagQ3.sort().map(n => 
                            <Chip label={n} color="primary" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
                { response.q4 &&
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='responseTitle'>{stats[statsId].q4.label}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Rating
                          readOnly
                          value={rateTransform(response.q4)}
                          precision={1}
                          size='large' />
                        <Grid item xs={12}>
                          {response.tagQ4 && response.tagQ4.sort().map(n => 
                            <Chip label={n} color="primary" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
                { response.q5 &&
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='responseTitle'>{stats[statsId].q5.label}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Rating
                          readOnly
                          value={rateTransform(response.q5)}
                          precision={1}
                          size='large' />
                        <Grid item xs={12}>
                          {response.tagQ5 && response.tagQ5.sort().map(n => 
                            <Chip label={n} color="primary" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
                { response.cq1 &&
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='responseTitle'>{stats[statsId].cq1.label}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Rating
                          readOnly
                          value={rateTransform(response.cq1)}
                          precision={1}
                          size='large' />
                        <Grid item xs={12}>
                          {response.tagCq1 && response.tagCq1.sort().map(n => 
                            <Chip label={n} color="primary" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
                { response.cq2 &&
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='responseTitle'>{stats[statsId].cq2.label}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Rating
                          readOnly
                          value={rateTransform(response.cq2)}
                          precision={1}
                          size='large' />
                        <Grid item xs={12}>
                          {response.tagCq2 && response.tagCq2.sort().map(n => 
                            <Chip label={n} color="primary" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <p className='responseTitle'>Comentário</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='responseUser'>{response.opinion ? response.opinion : 'Não informado'}</p>
                    </Grid>
                  </Grid>
                </ListItem>
                </List>
            </Grid>
          </Grid>
          <Dialog
            fullWidth
            maxWidth='xs'
            open={this.state.modal}
            onClose={this.toggleModal}
            aria-labelledby='alert-dialog-title'
          >
            <DialogTitle id='alert-dialog-title'>Legenda da Classificação</DialogTitle>
            <DialogContent dividers>
              <p>Essa classificação é baseada na respostas do usuário ao ser questionado quantas vezes visita/compra do seu estabelecimento.</p>
              <Paper>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Classificação</strong></TableCell>
                      <TableCell><strong>Resposta</strong></TableCell>
                      {showColumn && <TableCell><strong>Tipo</strong></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {user.tableFrequency.sort((a, b) => a[0] - b[0]).map(item => {
                    if (!showColumn) {
                      return(
                        <TableRow>
                          <TableCell component="th" scope="row">{item[1]}</TableCell>
                          <TableCell component="th" scope="row">{item[2]}</TableCell>
                        </TableRow>
                      )
                    } else {
                      return(
                        <TableRow>
                          <TableCell component="th" scope="row">{item[1]}</TableCell>
                          <TableCell component="th" scope="row">{item[2]}</TableCell>
                          <TableCell component="th" scope="row">{item[3]}</TableCell>
                        </TableRow>
                      )
                    }
                  })}
                  </TableBody>
                </Table>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                onClick={this.toggleModal}>
                Fechar
              </Button>              
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )
    }
  }
}

export default connect(state => state)(IndividualResponse);