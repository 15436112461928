import moment from 'moment';
import 'moment/locale/pt-br';

export function formatDate (str) {
  return moment(str).format('DD/MM/YYYY HH:mm');
};

export function rateTransform (str) {
  let rate = '';
  if (str !== null) { 
    let check = str.toUpperCase();
    if (check === 'MUITO INSATISFEITO') {
      rate = 1;
    } else if (check === 'INSATISFEITO') {
      rate = 2;
    } else if ( check === 'NEUTRO') {
      rate = 3;
    } else if (check === 'SATISFEITO') {
      rate = 4;
    } else if (check === 'MUITO SATISFEITO') {
      rate = 5;
    }
  } else {
    rate = 0
  }
  return rate;
}

export function capitalLetter(str) {
  str = str.trim().split(' ').filter(i => i !== '' && i !== null && i !== undefined);

  for (let i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase();
  }
  return str.join(' ').trim();
}

export function subItems(str) {
  const list = [];
  str.map(item => {
    let i = item.replace(/(\[|\])/g,'');
    list.push(i.split(", "))
    return null;
  })
  return list;
};

export function jsonFormat (str) {
  let list = [];
  let label = null;
  if (str !== null) {
    // transform String in JSON
    list = str.replace(/=/g,'":')
              .replace(/, /g,', "')
              .replace(/{/g,'{"');
    // check if label exist and transform label value in String
    label = list.match(/("label":[\wáàâãéèêíïóôõöúçñ]+\s+[\wáàâãéèêíïóôõöúçñ]+\s+[\wáàâãéèêíïóôõöúçñ]+)|("label":[\wáàâãéèêíïóôõöúçñ]+\s+[\wáàâãéèêíïóôõöúçñ]+)|("label":[\wáàâãéèêíïóôõöúçñ]+\/[\wáàâãéèêíïóôõöúçñ]+)|("label":[\wáàâãéèêíïóôõöúçñ]+)/g);
    if (label !== null) {
      label = label[0].split(':')[1];
      list = list.replace(label,'"'+label+'"');
    }
  }
  return list;
};