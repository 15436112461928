import { combineReducers } from 'redux';
import { 
  GET_USER_INFO, 
  GET_STATISTICS,
  GET_HISTORIC,
  GET_NEW_RESPONSES,
  GET_ALL_RESPONSES,
  FAVORITE_FLAG,
  RESPONSES_READY,
  SET_FILTER,
  GET_ALL_FAVORITES,
  SAVE_INDIVIDUAL_FAVORITE,
  REMOVE_FAVORITE,
  FAVORITES_READY } from '../actions';
import {
  jsonFormat,
  subItems } from '../utils/helper';

function user(state = {}, action) {
  const { id, clientId, name, createdAt, subItem, tableFrequency } = action
  switch (action.type) {
    case GET_USER_INFO :
      return {
        'id': id,
        'clientId': clientId,
        'name': name,
        'createdAt': createdAt,
        'subItem': subItem,
        'tableFrequency': subItems(tableFrequency)
      }
    default:
      return state
  }
}

function stats(state = {}, action) {
  const { responses, nps, q1, q2, q3, q4, q5, cq1, cq2, id } = action
  switch (action.type) {
    case GET_STATISTICS :
      return {
        ...state,
        [id]: {
          'responses': responses !== null ? JSON.parse(jsonFormat(responses)) : null,
          'nps': nps !== null ? JSON.parse(jsonFormat(nps)) : null,
          'q1': q1 !== null ? JSON.parse(jsonFormat(q1)) : null,
          'q2': q2 !== null ? JSON.parse(jsonFormat(q2)) : null,
          'q3': q3 !== null ? JSON.parse(jsonFormat(q3)) : null,
          'q4': q4 !== null ? JSON.parse(jsonFormat(q4)) : null,
          'q5': q5 !== null ? JSON.parse(jsonFormat(q5)) : null,
          'cq1': cq1 !== null ? JSON.parse(jsonFormat(cq1)) : null,
          'cq2': cq2 !== null ? JSON.parse(jsonFormat(cq2)) : null
        }
      }
    default :
      return state
  }
}

function responses(state = { responseReady: false, filter: 0, individual: [] }, action) {
  const { data, id, filter, payload } = action
  switch (action.type) {
    case GET_NEW_RESPONSES :
      return {
        ...state,
        individual: [
          ...data
        ]
      }
    case GET_ALL_RESPONSES :
      return {
        ...state,
        individual: [
          ...state.individual,
          ...data
        ]
      }
    case FAVORITE_FLAG :
      const updatedItems = state.individual.map(item => {
        if(item.id === id){
          return {
            ...item,
            favorite: payload
          }
        }
        return item
      })
      return {
        ...state,
        individual: updatedItems
      }
    case SET_FILTER:
      return {
        ...state,
        filter: filter
      };
    case RESPONSES_READY :
      return {
        ...state,
        responseReady: payload
      }
    default :
      return state
  }
}

function favorites(state = { favoritesReady: false, individual: [] }, action) {
  const { id, data, payload } = action
  switch (action.type) {
    case GET_ALL_FAVORITES :
      return {
        ...state,
        individual: [
          ...state.individual,
          ...data
        ]
      }
    case SAVE_INDIVIDUAL_FAVORITE :
      return {
        ...state,
        individual: [
          ...state.individual,
          data
        ]
      }
    case REMOVE_FAVORITE :
      return {
        ...state,
        individual: [
          ...state.individual.filter((item) => item.id !== id)
        ]
      }
    case FAVORITES_READY :
      return {
        ...state,
        favoritesReady: payload
      }
    default :
      return state
  }
}

function historic(state = {}, action) {
  const { responses, nps, q1, q2, q3, q4, q5, cq1, cq2, id } = action
  switch (action.type) {
    case GET_HISTORIC :
      return {
        ...state,
        [id]: {
          'responses': responses !== null ? JSON.parse(jsonFormat(responses)) : null,
          'nps': nps !== null ? JSON.parse(jsonFormat(nps)) : null,
          'q1': q1 !== null ? JSON.parse(jsonFormat(q1)) : null,
          'q2': q2 !== null ? JSON.parse(jsonFormat(q2)) : null,
          'q3': q3 !== null ? JSON.parse(jsonFormat(q3)) : null,
          'q4': q4 !== null ? JSON.parse(jsonFormat(q4)) : null,
          'q5': q5 !== null ? JSON.parse(jsonFormat(q5)) : null,
          'cq1': cq1 !== null ? JSON.parse(jsonFormat(cq1)) : null,
          'cq2': cq2 !== null ? JSON.parse(jsonFormat(cq2)) : null
        }
      }
    default :
      return state
  }
}

export default combineReducers({
  user,
  stats,
  responses,
  favorites,
  historic
});